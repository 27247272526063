import { themeGet } from '@styled-system/theme-get';
import styled from "styled-components"

export const BannerWrapper= styled.div`
  padding-top: 100px;
  padding-bottom: 20px;
  overflow: hidden;
  position: relative;
  background-image: -webkit-linear-gradient(
    270deg,
    rgb(13, 125, 248) 30%,
    rgb(255, 255, 255) 10%
  );
   
  > div.container {
    display: block;;
    @media only screen and (min-width: 1201px) and (max-width: 1440px) {
      min-height: 100%;
      margin-top: 15px;
    }
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
  }
  .bannerBottomShape {
    position: absolute;
    right: 0;
    bottom: -2px;
    @media only screen and (max-width: 999px) {
      display: none;
    }
  }
`;

export const BannerContent = styled.div`
  width: 60%;
  flex-shrink: 0;
  @media only screen and (max-width: 1199px) {
    flex-shrink: 0;
    width: 100%;
    margin-top: 25px;
  }
  @media only screen and (min-width: 1366px) {
    width: 94%;
    line-height: 42px;
    margin-bottom: 20px;
  }


  h1 {
    color: ${themeGet('colors.headingColor', '#0F2137')};
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 24px;
    @media only screen and (min-width: 480px) and (max-width: 1366px) {
      font-size: 29px;
    }  
    @media only screen and (min-width: 1366px) {
      font-size: 36px;
      margin: 40px 0;
    }  
  }

`;

export const InsightWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 10px;
  min-height: 200px;
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(200px - 100px);
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 780px) {
      flex-wrap: wrap;
    }
  }
`;



export const ProposalWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
  margin-bottom: 20px;
  min-height: 200px;
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(400px - 100px);
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 780px) {
      flex-wrap: wrap;
    }
  }
`;

export const ImageWrapper = styled.div`
  margin-left: -30px;
 
`;


export const ScholarshipWrapper = styled.div`
  // padding-top: 50px;
  padding-bottom: 10px;
  min-height: 200px;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 5px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(200px - 100px);
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 780px) {
      flex-wrap: wrap;
    }
  }
`;

export const TradeWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 10px;
  min-height: 200px;
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(200px - 100px);
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 780px) {
      flex-wrap: wrap;
    }
  }
`;

export const TextWrapper = styled.div`
  width: 428px;
  align-self: center;
  margin-top: -50px; 
  @media only screen and (min-width: 1220px) {
    padding-left: 25px;
    width: 500px;
    margin-right: 40px;
    margin-top: -37px;
  }
  @media only screen and (max-width: 1220px) {
    padding-left: 40px;
  }
  @media only screen and (max-width: 991px) {
    width: 350px;
    padding-left: 40px;
    margin-right: -28px;
  }
  @media only screen and (max-width: 780px) {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
  h3 {
    color: ${themeGet('colors.headingColor', '#0F2137')};
    font-size: 27px;
    line-height: 23px;
    font-weight: 600;
    margin-bottom: 20px;
    @media only screen and (max-width: 1366px) {
      font-size: 26px;
      line-height: 31px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    @media only screen and (min-width: 1220px) {
      font-size: 28px;
      line-height: 50px;
      margin-bottom: 15px;
      margin-top: 20px;
    }
  }
  p {
    color: ${themeGet('colors.textColor', 'rgba(51, 51, 51)')};
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
    @media only screen and (min-width: 1220px) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 15px;
      margin-top: 20px;
    }
  }
  > p {
    margin-bottom: 20px;
  }
  button {
    margin-top: 20px
  }
  #text{
    @media only screen and (min-width: 1220px) {
      margin-top: -21px;
      margin-bottom: 30px;
    }

  }
  .alpha{
    @media only screen and (min-width: 1220px) {
      padding-left: 5px;
      width: 459px;
      margin-top: 46px;
      margin-bottom: 54px;
    }
    button {
      font-weight: 600;
      @media only screen and (min-width: 1220px) { 
        margin-top: 32px;
        margin-bottom: -4px;
      }
      @media only screen and (min-width: 991px) and (max-width: 1220px) { 
        margin-top: 56px;
        margin-bottom: -15px;
      }
    }
  }
  .trade{
    @media only screen and (min-width: 1220px) {
      padding-left: 5px;
      width: 418px;
      margin-top: 42px;
      margin-bottom: 54px;
    }
    button {
      font-weight: 600;
      @media only screen and (min-width: 1220px) { 
        margin-top: 25px;
        margin-bottom: -7px;
      }
      @media only screen and (min-width: 991px) and (max-width: 1220px) { 
        margin-top: 76px;
        margin-bottom: -15px;
      }
    }
  }
  .scholar{
    @media only screen and (min-width: 1220px) {
      padding-left: 5px;
      width: 417px;
      line-height: 20px;
      margin-top: 51px;
      margin-bottom: 54px;
    }
    button {
      font-weight: 600;
      @media only screen and (min-width: 1220px) { 
        margin-top: 29px;
        margin-bottom: -13px;
      }
      @media only screen and (min-width: 991px) and (max-width: 1220px) { 
        margin-top: 93px;
        margin-bottom: -18px;
      }
    }
  }
  .sals{
    @media only screen and (min-width: 1220px) {
      padding-left: 5px;
      width: 445px;
      margin-top: 50px;
      margin-bottom: 54px;
    }
    button {
      font-weight: 600;
      @media only screen and (min-width: 1220px) { 
        margin-top: 6px;
      }
      @media only screen and (min-width: 991px) and (max-width: 1220px) { 
        margin-top: 65px;
        margin-bottom: -15px;
      }
    }
  }
  
`;




