import { ImageWrapper, ScholarshipWrapper, InsightWrapper, TextWrapper, TradeWrapper, ProposalWrapper, BannerWrapper, BannerContent } from './style';

import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import { openUrl } from '../../../utils/misc';
// import { SectionWrapper } from './SectionWrapper'; // Make sure to import SectionWrapper
// import { ImageWrapper } from '../Navbar/navbar.style';
import Image1 from 'common/assets/image/appModern/tradeFare.png';
import Image2 from 'common/assets/image/appModern/scholarship.png';
import Image3 from 'common/assets/image/appModern/proposal.png';
import Image4 from 'common/assets/image/appModern/insight.png';

export const ProposalSection = () => ( 
  <ProposalWrapper>
    <Container>
      <ImageWrapper>
        <Fragment>
          <img 
            src={Image3} 
            height={400} 
            alt='Spinning SAMO' 
          />
        </Fragment>
      </ImageWrapper>
      <TextWrapper > 
       <div className="sals">
          <Heading 
            as="h3" 
            content="PROPOSALS" 
          />
          <Text 
            content="Are you a SamoDAO member and own 1M $SAMO. Want to propose an adjustment and/or change to the SamoDAO?! Head over to Discord and submit a proposal for the community to vote on." 
          />
          <br></br>
          <Text 
            id="text" 
            content="Proposals open the first week of the month." 
          />
          <Button 
            title="JOIN SAMO DISCORD" 
            onClick={() => openUrl('https://discord.gg/8Fq3Z568tp')}
          />
        </div>
      </TextWrapper>
    </Container>
  </ProposalWrapper>
)

export const InsightSection = () => (
  <InsightWrapper>
    <Container>
      <ImageWrapper>
        <Fragment>
          <img 
            src={Image4} 
            height={400} 
            alt=""  
          />
        </Fragment>
      </ImageWrapper>
      <TextWrapper>
        <div className="alpha">
          <Heading 
            as="h3" 
            content="SamoDAO INSIGHTS & ALPHAS"
          />
          <Text 
            content="SamoDAO's Top Dog Council approved Glassnode & CoinMarketCap API subscriptions for enhanced market insights. Verified SamoDAO members can access at any hour! Explore our updated SD Insights channels including top-signals & trending now!" 
          />
          <Button 
            title="JOIN SAMO DISCORD" 
            onClick={() => openUrl('https://discord.gg/8Fq3Z568tp')}
          />
        </div>
      </TextWrapper>
    </Container>
  </InsightWrapper>
)

export const TradeSection = () => (
  <TradeWrapper>
    <Container>
      <ImageWrapper>
        <Fragment>
          <img 
            src={Image1} 
            height={400} 
            alt="d" 
          />
        </Fragment>
      </ImageWrapper>
      <TextWrapper>
        <div className="trade">
          <Heading 
          as="h3" 
          content="TRADE OR FADE" />
          <Text 
            content="Dive into crypto research and showcase at SamoDAO's weekly event 'Trade or Fade' every Tuesday @ 11AM ET." 
          />
          <br></br>
          <Text 
            id="text"
            content="Share insights on emerging projects with our community!" 
          />
          <Button 
            title="WATCH PAST SESSIONS" 
            onClick={() => openUrl('https://www.youtube.com/@samodao/videos')}
          />
        </div>
      </TextWrapper>
    </Container>
  </TradeWrapper>
)

export const HeadingSection = () => (
  <BannerWrapper id="home">
    <Container>
      <BannerContent>
        <Fade up delay={100}>
          <Heading 
            as="h1" 
            content="Benefits SamoDAO Members Get Access To" 
          />
        </Fade>
      </BannerContent>
    </Container>
  </BannerWrapper>
)

export const ScholarshipSection = () => (
  <ScholarshipWrapper>
    <Container>
      <ImageWrapper>
        <Fragment>
          <img 
            src={Image2} 
            height={400} 
            alt='SamoDAO Scholarship' 
          />
        </Fragment>
      </ImageWrapper>
      <TextWrapper>
        <div className="scholar">
          <Heading 
            as="h3" 
            content="SamoDAO Scholarship" 
          />
          <Text 
            content="Passionate about crypto research? Explore our three SamoDAO Scholarships, earn SAMO, and share your expertise!" 
          />
          <br></br>
          <Text 
            id="text"
            content="Dive in at 🎓scholarships." 
          />
          <Button 
            title="JOIN SAMO DISCORD" 
            onClick={() => openUrl('https://discord.gg/8Fq3Z568tp')}
          />
        </div>
      </TextWrapper>
    </Container>
  </ScholarshipWrapper>
);
